
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const AdminRegister = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiurl}/api/register`, {
        email,
        password,
      });
      

      // Show toast notification upon successful registration
      toast.success(response.data.message);
    } catch (error) {
      // Handle error and display error message
      toast.error(error.response.data.message);
      
    }
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <h3 className="text-center mt-3">Register</h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              className="w-full"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-2">
            Register
          </Button>
        </Form>
        <div className="text-center mt-3">
          Already have an account? <Link to="/adminlogin">Login</Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminRegister;
