/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const AssignTasks = () => {
  const [ticketNumbers, setTicketNumbers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTicketNumbers = async () => {
      try {
        const response = await axios.get(`${apiurl}/getdocuments`);
        setTicketNumbers(response.data);
      } catch (error) {
        console.error("Error fetching ticket numbers:", error);
      }
    };

    const fetchAdmins = async () => {
      try {
        const response = await axios.get(`${apiurl}/getAdmins`);
        setAdmins(response.data.admins);
      } catch (error) {
        console.error("Error fetching admins:", error);
      }
    };

    fetchTicketNumbers();
    fetchAdmins();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${apiurl}/tasks`);
        setTasks(response.data.tasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, []);

  const handleAssignTask = async () => {
    try {
      if (!selectedTicket || !selectedAdmin || !taskDescription) {
        toast.error("Please fill in all fields");
        return;
      }

      // Check if the selected ticket is already assigned to an admin
      const existingTaskForTicket = tasks.find(
        (task) => task.ticketNumber === selectedTicket
      );
      if (existingTaskForTicket) {
        toast.error("This ticket is already assigned to an admin");
        return;
      }

      const selectedAdminObject = admins.find(
        (admin) => admin._id === selectedAdmin
      );
      const adminEmail = selectedAdminObject ? selectedAdminObject.email : "";

      await axios.post(`${apiurl}/assignTask`, {
        title: "Task Title",
        description: taskDescription,
        adminId: selectedAdmin,
        adminEmail: adminEmail,
        ticketNumber: selectedTicket,
      });

      setSelectedTicket("");
      setSelectedAdmin("");
      setTaskDescription("");

      toast.success("Task assigned successfully");
    } catch (error) {
      console.error("Error assigning task:", error);
      alert("An error occurred while assigning task");
    }
  };

  return (
    <>
      <Container className="mt-3 mainassigntask">
        <div className="d-flex manage">
          <Link to="/userdoc">
            <IoArrowBackCircle size={44} className="arrowback" />
          </Link>

          <div className="mx-2">
            <h1>Assign Tasks</h1>
          </div>
        </div>
        <Row>
          <Col md={4}>
            <form>
              <div className="form-group" controlId="ticketNumber">
                <label>Ticket Number:</label>
                <select
                  className="form-control"
                  value={selectedTicket}
                  onChange={(e) => setSelectedTicket(e.target.value)}
                >
                  <option value="">Select Ticket Number</option>
                  {ticketNumbers.map((ticket) => (
                    <option key={ticket._id} value={ticket.ticketNumber}>
                      {ticket.ticketNumber}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mt-2" controlId="admin">
                <label>Assign To:</label>
                <select
                  className="form-control"
                  value={selectedAdmin}
                  onChange={(e) => setSelectedAdmin(e.target.value)}
                >
                  <option value="">Select Admin</option>
                  {admins.map((admin) => (
                    <option key={admin._id} value={admin._id}>
                      {admin.email}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mt-2" controlId="taskDescription">
                <label>Task Description:</label>
                <textarea
                  className="form-control mt-1"
                  value={taskDescription}
                  onChange={(e) => setTaskDescription(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="aasigntaskbtn mt-4 p-2  "
                onClick={handleAssignTask}
              >
                Assign Task
              </button>
            </form>
          </Col>
          <Col md={4}></Col>
        </Row>
        <hr />
        <Row className="mt-3">
          <Col className="col-md-12 m-auto tableclasses">
            <h2 className="text-start pt-3 pb-3">Assign tasks</h2>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ padding: "8px" }}>Title</th>
                  <th style={{ padding: "8px" }}>Description</th>
                  <th style={{ padding: "8px" }}>Assigned To</th>
                  <th style={{ padding: "8px" }}>Ticket Number</th>
                </tr>
              </thead>

              <tbody>
                {tasks.map((task) => (
                  <tr key={task._id}>
                    <td style={{ padding: "8px" }}>{task.title}</td>
                    <td style={{ padding: "8px" }}>{task.description}</td>
                    <td style={{ padding: "8px" }}>{task.adminEmail}</td>
                    <td style={{ padding: "8px" }}>{task.ticketNumber}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default AssignTasks;
