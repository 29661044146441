import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles
import { FaTrashAlt } from "react-icons/fa"; // Import the trash icon
import axios from "axios"; // Import axios for making API requests
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const ManageAdmins = () => {
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${apiurl}/getAdmins`);
      const fetchedAdmins = response.data.admins;
      // Map fetched admins to include last action based on approved field
      const adminsWithLastAction = fetchedAdmins.map((admin) => ({
        ...admin,
        lastAction: admin.approved ? "Approved" : "Rejected",
      }));
      setAdmins(adminsWithLastAction);
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const handleDeleteAdmin = async (adminId) => {
    try {
      await axios.delete(`${apiurl}/deleteAdmin/${adminId}`);
      // Remove the deleted admin from the state
      const updatedAdmins = admins.filter((admin) => admin._id !== adminId);
      setAdmins(updatedAdmins);
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
  };

  const handleApproveAdmin = async (adminId) => {
    try {
      await axios.put(`${apiurl}/approveAdmin/${adminId}`);
      // Update the admin status in the state
      const updatedAdmins = admins.map((admin) =>
        admin._id === adminId
          ? { ...admin, approved: true, lastAction: "Approved" }
          : admin
      );
      setAdmins(updatedAdmins);
    } catch (error) {
      console.error("Error approving admin:", error);
    }
  };

  const handleRejectAdmin = async (adminId) => {
    try {
      await axios.put(`${apiurl}/rejectAdmin/${adminId}`);
      // Update the admin status in the state
      const updatedAdmins = admins.map((admin) =>
        admin._id === adminId
          ? { ...admin, approved: false, lastAction: "Rejected" }
          : admin
      );
      setAdmins(updatedAdmins);
    } catch (error) {
      console.error("Error rejecting admin:", error);
    }
  };

  return (
    <div>
      <div className="d-flex manageadminc">
        <Link to="/userdoc">
          <IoArrowBackCircle size={44} className="arrowback" />
        </Link>

        <div className="mx-5">
          <h1>Manage Admins</h1>
        </div>
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Status</th>
              <th>Last Action</th>
              <th>Action</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin._id}>
                <td>{admin.email}</td>
                <td>{admin.status}</td>
                <td>{admin.lastAction || "-"}</td>
                <td>
                  <button
                    className="btn btn-success mx-2"
                    onClick={() => handleApproveAdmin(admin._id)}
                    disabled={admin.approved}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-warning mx-2"
                    onClick={() => handleRejectAdmin(admin._id)}
                    disabled={!admin.approved}
                  >
                    Reject
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteAdmin(admin._id)}
                  >
                    <FaTrashAlt />
                  </button>
                </td>
                <td>{admin.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageAdmins;
