import React, { useEffect, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const SuccessfulPaymentsUsers = () => {
  const [allPayments, setAllPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`${apiurl}/api/payments`);
        const data = await response.json();
        setAllPayments(data);
        setFilteredPayments(data);
      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    };

    fetchPayments();
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredPayments(allPayments);
    } else {
      const filtered = allPayments.filter((payment) =>
        payment.ticketNumber.includes(searchQuery)
      );
      setFilteredPayments(filtered);
    }
  }, [searchQuery, allPayments]);

  return (
    <div className="container mt-5">
      <div className="d-flex">
        <Link to="/userdoc">
          <IoArrowBackCircle size={44} className="arrowback" />
        </Link>

        <div className="mx-5">
          <h1>Successful Payments</h1>
        </div>
      </div>

      <div className="form-group">
        <div className="search-bar mt-2">
          <input
            type="text"
            className="form-control mt-3 mb-3 "
            id="ticketNumberSearch"
            placeholder="Search by Ticket Number"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      {filteredPayments.length > 0 ? (
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>Payment ID</th>
              <th>Amount</th>
              <th>Ticket Number</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredPayments.map((payment) => (
              <tr key={payment._id}>
                <td>{payment.razorpay_payment_id}</td>
                <td>{payment.amount}</td>
                <td>{payment.ticketNumber}</td>
                <td>{new Date(payment.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No payments found.</p>
      )}
    </div>
  );
};

export default SuccessfulPaymentsUsers;
