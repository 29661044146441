import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const AdminLogin = ({ onAdminLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiurl}/api/login`, {
        email,
        password,
      });
      const { token, message, email: loggedInEmail } = response.data;

      toast.success(`${message}. Logged in as ${loggedInEmail}`);

      localStorage.setItem('admin', token);
      localStorage.setItem('adminEmail', email);
      if (onAdminLogin) {
        onAdminLogin(token);
      }

      // Navigate to admin home immediately after successful login
      navigate('/adminhome', { state: { email: loggedInEmail, token } });

      setTimeout(() => {
        localStorage.removeItem('admin');
        localStorage.removeItem('adminEmail');
        navigate('/adminlogin');
      }, 300000);

    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h3 className="text-center mt-3">Login</h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="mt-3">Email address</Form.Label>
            <Form.Control
              type="email"
              className="w-full "
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-2">
            Login
          </Button>
        </Form>
        <div className="mt-2">
          Create new account <Link to="/admin/register">Register</Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminLogin;
