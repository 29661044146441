import React, { useState } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const apiurl = process.env.REACT_APP_API_URL_BACKEND;


const SuperAdminLogin = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // Corrected the variable name to navigate

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please fill in both fields");
      return;
    }

    setLoading(true); // Set loading to true

    try {
      const response = await axios.post(`${apiurl}/superadminlogin`, {
        email,
        password,
      });
      const { token, message } = response.data;

      toast.success(message);

      // Save token to local storage
      localStorage.setItem("superadminToken", token);
      if (onLogin) {
        onLogin(token);
      }
      // Redirect to Userdocuments after successful login
      navigate("/userdoc");
      setTimeout(() => {
        localStorage.removeItem("superadminToken");

        navigate("/");
      }, 300000);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h3 className="text-center mt-3">SuperAdmin Login</h3>
        {error && <Alert variant="danger">{error}</Alert>}{" "}
        {/* Display error message */}
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!error} // Highlight input if there's an error
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={!!error} // Highlight input if there's an error
            />
          </Form.Group>

          <Button
            variant="primary"
            className="mt-3"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Login"}{" "}
            {/* Show spinner while loading */}
          </Button>
        </Form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SuperAdminLogin;
