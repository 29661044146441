import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrashAlt } from "react-icons/fa";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const ContactMessages = () => {
  const [contactMessages, setContactMessages] = useState([]);

  useEffect(() => {
    const fetchContactMessages = async () => {
      try {
        // Fetch contact messages from the backend
        const response = await axios.get(`${apiurl}/contacts`);
        const { contacts } = response.data;
        setContactMessages(contacts);
      } catch (error) {
        console.error("Error fetching contact messages:", error);
      }
    };

    fetchContactMessages();
  }, []);

  const handleDeleteMessage = async (id) => {
    try {
      await axios.delete(`${apiurl}/contacts/${id}`);

      setContactMessages(
        contactMessages.filter((contact) => contact._id !== id)
      );
    } catch (error) {
      console.error("Error deleting contact message:", error);
    }
  };

  return (
    <div className="col-md-12">
      <div className="d-flex manageadminc">
        <Link to="/userdoc">
          <IoArrowBackCircle size={44} className="arrowback" />
        </Link>

        <div className="mx-5">
          <h1>Contact Messages</h1>
        </div>
      </div>
      <table className="table ">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Message</th>
            <th scope="col">Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {contactMessages.map((contact) => (
            <tr key={contact._id}>
              <td>{contact.name}</td>
              <td>{contact.email}</td>
              <td>{contact.phoneNumber}</td>
              <td>{contact.message}</td>
              <td>{contact.created_at}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteMessage(contact._id)}
                >
                  <FaTrashAlt />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactMessages;
