/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { FaDownload } from "react-icons/fa6";

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const Adminhome = ({ adminlogout }) => {
  const [selectedStatus, setSelectedStatus] = useState({});
  const [ticketStatus, setTicketStatus] = useState("");
  const [tasks, setTasks] = useState([]);
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const email = state && state.email ? state.email : "No email found";
  const ticketNumbers = tasks.map((task) => task.ticketNumber);

  // Modify the useEffect hook to fetch the status of each document
  useEffect(() => {
    const fetchTicketStatus = async () => {
      try {
        // Iterate through each ticket number and fetch its status
        const statusPromises = ticketNumbers.map(async (ticketNumber) => {
          const response = await axios.get(
            `${apiurl}/getTicketStatus/${ticketNumber}`
          );
          return { ticketNumber, status: response.data.status };
        });

        // Wait for all status requests to complete
        const ticketStatusArray = await Promise.all(statusPromises);

        // Convert the array to an object where ticketNumber is the key and status is the value
        const ticketStatusObject = ticketStatusArray.reduce((acc, cur) => {
          acc[cur.ticketNumber] = cur.status;
          return acc;
        }, {});

        // Set the ticketStatus state with the fetched statuses
        setTicketStatus(ticketStatusObject);
      } catch (error) {
        console.error("Error fetching ticket statuses:", error);
      }
    };

    // Fetch ticket statuses only when there are ticket numbers
    if (ticketNumbers.length > 0) {
      fetchTicketStatus();
    }
  }, [ticketNumbers]);

  const handleAdminStatusChange = async (ticketNumber, selectedStatus) => {
    try {
      console.log(
        "Received request to update ticket status. Ticket Number:",
        ticketNumber,
        "New Status:",
        selectedStatus
      );

      const response = await axios.put(
        `${apiurl}/updateTicketStatus/${ticketNumber}`,
        { adminStatus: selectedStatus },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSelectedStatus((prevStatus) => ({
        ...prevStatus,
        [ticketNumber]: selectedStatus,
      }));

      toast.success("Admin status updated successfully");
    } catch (error) {
      toast.error("An error occurred while updating admin status");
    }
  };

  const handleProceedToPayment = async (ticketNumber) => {
    try {
      const response = await axios.put(
        `${apiurl}/proceedToPayment/${ticketNumber}`
      );

      if (response.status === 200) {
        toast.success("Proceed to Payment initiated successfully");
      } else {
        toast.error("Failed to initiate payment. Please try again later.");
      }
    } catch (error) {
      console.error("Error proceeding to payment:", error);
      toast.error("An error occurred while proceeding to payment");
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `${apiurl}/api/tasks/${email}`
        );
        setTasks(response.data.tasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [email]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const documentRequests = ticketNumbers.map((ticketNumber) =>
          axios.get(`${apiurl}/${ticketNumber}`)
        );
        const documentResponses = await Promise.all(documentRequests);
        const documentsData = documentResponses.map(
          (response) => response.data.documents
        );
        setDocuments(documentsData.flat());
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    if (ticketNumbers.length > 0) {
      fetchDocuments();
    }
  }, [ticketNumbers]);

  const handleLogoutClick = () => {
    adminlogout();
    navigate("/adminlogin");
  };

  const downloadDocument = (documentData, contentType, fileName) => {
    if (documentData) {
      const byteCharacters = atob(documentData.split(",")[1]);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
    } else {
      console.error("Document data is undefined.");
    }
  };

  return (
    <>
      <div className="bg-dark text-white ">
        <div className="row">
          <div className="col">
            <h1 className="text-center mt-3">Vendor Home</h1>
          </div>
          <div className="col text-end">
            <button
              variant="danger"
              className="btn btn-danger mt-3 mx-5"
              onClick={handleLogoutClick}
            >
              Logout
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col text-end mt-2 mx-2">
            <h6>Welcome, {email}!</h6>
          </div>
        </div>
      </div>

      <div className="mt-3 table-responsive ">
        <Table className="table" striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Ticket Number</th>
              <th>Documents</th>
              <th>Status</th>
              <th>Last Changed Status</th>
            </tr>
          </thead>

          <tbody>
            {tasks.map((task) => (
              <tr key={task._id}>
                <td>{task.title}</td>
                <td>{task.description}</td>
                <td>{task.ticketNumber}</td>
                <td>
                  <ul>
                    {documents
                      .filter((doc) => doc.ticketNumber === task.ticketNumber)
                      .map((doc, index) => (
                        <li key={index}>
                          <div>
                            <strong>Email:</strong> {doc.email}
                          </div>
                          <div>
                            <strong>Mobile Number:</strong> {doc.mobilenumber}
                          </div>

                          <div className="mt-2">
                            <Button
                              onClick={() =>
                                downloadDocument(
                                  doc.pancardBase64,
                                  "application/pdf",
                                  "Pancard.pdf"
                                )
                              }
                            >
                              Download Pancard
                            </Button>
                          </div>
                          <div className="mt-2">
                            <Button
                              onClick={() =>
                                downloadDocument(
                                  doc.adharCardBase64,
                                  "application/pdf",
                                  "AdharCard.pdf"
                                )
                              }
                            >
                              Download Adhar Card
                            </Button>
                          </div>
                          <div className="mt-2">
                            <Button
                              onClick={() =>
                                downloadDocument(
                                  doc.loanStatementBase64,
                                  "application/pdf",
                                  "LoanStatement.pdf"
                                )
                              }
                            >
                              Download Loan Statement
                            </Button>
                          </div>
                          <div className="mt-2">
                            <Button
                              onClick={() =>
                                downloadDocument(
                                  doc.bankStatementBase64,
                                  "application/pdf",
                                  "BankStatement.pdf"
                                )
                              }
                            >
                              Download Bank Statement
                            </Button>
                          </div>
                          <div className="mt-2">
                            <Button
                              onClick={() =>
                                downloadDocument(
                                  doc.form16Base64,
                                  "application/pdf",
                                  "Form16.pdf"
                                )
                              }
                            >
                              Download Form 16
                            </Button>
                          </div>
                          <div className="mt-2">
                            <Button
                              onClick={() =>
                                downloadDocument(
                                  doc.interestCertificateBase64,
                                  "application/pdf",
                                  "InterestCertificate.pdf"
                                )
                              }
                            >
                              Download Interest Certificate
                            </Button>
                          </div>
                          <div className="mt-2">
                            <Button
                              onClick={() =>
                                downloadDocument(
                                  doc.excelSheetBase64,
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                  "ExcelSheet.xlsx"
                                )
                              }
                            >
                              Download Excel Sheet
                            </Button>
                          </div>
                        </li>
                      ))}
                  </ul>
                </td>
                <td>
                  <select
                    value={selectedStatus[task.ticketNumber] || ""}
                    onChange={(e) =>
                      handleAdminStatusChange(task.ticketNumber, e.target.value)
                    }
                  >
                    <option value="Upload Documents">Select Status</option>
                    <option value="Process in verification">
                      Process in Verification
                    </option>
                    <option value="Verified">Verified</option>
                    <option value="Proceed to payment">
                      Proceed to Payment
                    </option>
                    <option value="Hold">Hold</option>
                    <option value="Successfully filled">
                      Successfully Filled
                    </option>
                    <option value="Rejected">Rejected</option>
                  </select>
                  <td>{selectedStatus[task.ticketNumber]}</td>
                </td>

                <td>{ticketStatus[task.ticketNumber]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ToastContainer />
    </>
  );
};

export default Adminhome;
