import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Userdocuments from "./components/Userdocuments";
import SuperAdminLogin from "./components/Superadminlogin";
import Contactmessage from "./components/ContactMessages";
import Assigntasks from "./components/Assigntasks";
import AdminLogin from "./components/AdminLogin";
import AdminRegister from "./components/AdminRegister";
import Adminhome from "./components/Adminhome";
import Manageadmins from "./components/Manageadmins";
import SuccessfulPaymentsUsers from "./components/Successfulpaymentsusers";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [adminlogin, setAdminLogin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("superadminToken");
    if (token) {
      setIsLoggedIn(true);
    }

    const adminToken = localStorage.getItem("admin");
    if (adminToken) {
      setAdminLogin(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("superadminToken");
    setIsLoggedIn(false);
  };

  const handleAdminLogin = () => {
    setAdminLogin(true);
  };

  const handleAdminLogout = () => {
    localStorage.removeItem("admin");
    setAdminLogin(false);
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/userdoc" />
              ) : (
                <SuperAdminLogin onLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/userdoc"
            element={
              isLoggedIn ? (
                <Userdocuments onLogout={handleLogout} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/userdoc/Contactmessage"
            element={isLoggedIn ? <Contactmessage /> : <Navigate to="/" />}
          />
          <Route
            path="/userdoc/assigntasks"
            element={isLoggedIn ? <Assigntasks /> : <Navigate to="/" />}
          />
          <Route
            path="/userdoc/alladmins"
            element={isLoggedIn ? <Manageadmins /> : <Navigate to="/" />}
          />
           
           <Route
            path="/userdoc/payments"
            element={isLoggedIn ? <SuccessfulPaymentsUsers /> : <Navigate to="/" />}
          />
         
          {adminlogin ? (
            <Route
              path="/adminhome"
              element={<Adminhome adminlogout={handleAdminLogout} />}
            />
          ) : (
            <Route
              path="/adminlogin"
              element={<AdminLogin onAdminLogin={handleAdminLogin} />}
            />

          )}
          <Route path="/admin/register" element={<AdminRegister />} />
          <Route path="*" element={<AdminLogin />} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
