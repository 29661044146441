/* eslint-disable no-unused-vars */
/// if below code runing thend  delete above code issue was check payment status

import React, { useEffect, useState } from "react";
import { Table, Navbar } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { MdFileDownload } from "react-icons/md";

const apiurl=process.env.REACT_APP_API_URL_BACKEND


const Userdocuments = ({ onLogout }) => {
  const [pdfData, setPdfData] = useState([]);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState({});
  const [ticketn, setticketm] = useState("");
  const [admins, setAdmins] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [paymentStatus, setPaymentStatus] = useState({});

  const fetchAdminsData = async () => {
    try {
      const response = await axios.get(`${apiurl}/getAdmins`);
      setAdmins(response.data);
    } catch (error) {
      console.error("Error fetching admins data:", error);
    }
  };

  useEffect(() => {
    fetchAdminsData(); // Fetch admins data when component mounts
  }, []);

  const fetchPDFData = async () => {
    try {
      const response = await fetch(`${apiurl}/getdocuments`);

      if (!response.ok) {
        throw new Error("Failed to fetch PDF data");
      }
      const data = await response.json();

      setticketm(data.ticketNumber);
      return data;
    } catch (error) {
      console.error("Error fetching PDF data:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const documents = await fetchPDFData();

        setPdfData(documents);

        // Fetch and set the last changed status for each document
        const statuses = {};
        for (const document of documents) {
          const response = await axios.get(
            `${apiurl}/tickets/${document.ticketNumber}`
          );
          statuses[document.ticketNumber] = response.data.status;
        }
        setSelectedStatus(statuses);
      } catch (error) {
        console.error("Error setting PDF data:", error);
      }
    };
    fetchData();
  }, []);

  // update ticket status

  const downloadPdf = (pdfBase64, filename) => {
    const link = document.createElement("a");
    link.href = pdfBase64;
    link.download = filename;
    link.click();
  };

  const downloadFile = (fileBase64, filename, contentType) => {
    const byteCharacters = atob(fileBase64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  const handleAdminStatusChange = async (ticketNumber, selectedStatus) => {
    try {
      
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        `${apiurl}/updateTicketStatus/${ticketNumber}`,
        { adminStatus: selectedStatus }, // Correct the field name to 'adminStatus'
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSelectedStatus((prevStatus) => ({
        ...prevStatus,
        [ticketNumber]: selectedStatus,
      }));

      toast.success("Admin status updated successfully");
    } catch (error) {
      toast.error("An error occurred while updating admin status");
    }
  };

  const handleLogoutClick = () => {
    onLogout();
    navigate("/");
  };

  const handleDeleteDocument = async (ticketNumber) => {
    try {
      const response = await axios.delete(
        `${apiurl}/documents/${ticketNumber}`
      );

      if (response.status === 200) {
        toast.success("Document deleted successfully");
        setPdfData((prevPdfData) =>
          prevPdfData.filter((doc) => doc.ticketNumber !== ticketNumber)
        );
      } else {
        toast.error("Failed to delete document");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.error("An error occurred while deleting document");
    }
  };

  return (
    <>
      <div className="maindiv">
        <div className="d-flex justify-content-between">
          <h2 className="text-start pt-3 pb-3   sticky">User Documents</h2>
          <div className="pt-3 ">
            <button className="btn btn-danger p-2" onClick={handleLogoutClick}>
              Logout
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-between navabrbg">
          <div>
            <Link
              to="/userdoc/assigntasks"
              className="mt-3 mx-5 links text-decoration-none fs-4"
            >
              Assign Tasks
            </Link>
            <Link
              to="/userdoc/Contactmessage"
              className="mt-3 mx-5 links fs-4 text-decoration-none"
            >
              Contact Queries
            </Link>
            <Link
              to="/userdoc/alladmins"
              className="mt-3 mx-5 links fs-4 text-decoration-none"
            >
              Choose Vendors
            </Link>
            <Link
              to="/userdoc/payments"
              className="mt-3 mx-5 links fs-4 text-decoration-none"
            >
              Payments
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          <table className="custom-table mt-5">
            <thead className="">
              <tr>
                <th>Email</th>
                <th>Ticket Number</th>
                <th>Bank Ac/No</th>
                <th>Password</th>
                <th>Mobile Number</th>
                <th>Document Type</th>
                <th>Document</th>
                <th>Admin Status</th>
                <th>Last Status </th>
                <th>Download </th>
                <th>Actions</th>
                <th>Referral Discount</th>
              </tr>
            </thead>
            <tbody className="tablebody">
              {pdfData.map((document, index) => (
                <tr key={index} className="">
                  <td>{document.email}</td>
                  <td>{document.ticketNumber}</td>
                  <td>
                    {document.bankAccountnumberBase64}
                    <br />
                    {document.ifsccode}
                  </td>
                  <td>{document.password}</td>
                  <td>{document.mobilenumber}</td>
                  <td>{document.documentType}</td>
                  <td>
                    {Object.entries(document).map(([fieldName, fieldValue]) => {
                      if (fieldName.endsWith("Base64")) {
                        return (
                          <div key={fieldName} className="mt-3">
                            {fieldName.replace("Base64", "")}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </td>
                  <td>
                    <select
                      value={selectedStatus[document.ticketNumber] || ""}
                      onChange={(e) =>
                        handleAdminStatusChange(
                          document.ticketNumber,
                          e.target.value
                        )
                      }
                    >
                      <option value="Upload Documnets">Select Status</option>
                      <option value="Process in verification">
                        Process in Verification
                      </option>
                      <option value="Verified">Verified</option>
                      <option value="Proceed to payment">
                        Proceed to Payment
                      </option>
                      <option value="Hold">Hold</option>
                      <option value="Successfully filled">
                        Successfully Filled
                      </option>
                      <option value="Rejected">Rejected</option>
                    </select>
                    <div>Status: {selectedStatus[document.ticketNumber]}</div>
                  </td>
                  <td>{selectedStatus[document.ticketNumber]}</td>
                  <td className="btn-column">
                    {Object.entries(document).map(([fieldName, fieldValue]) => {
                      if (fieldName.endsWith("Base64")) {
                        const contentType =
                          fieldName === "excelSheetBase64"
                            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            : "application/pdf";

                        return (
                          <div key={fieldName}>
                            <button
                              className="mt-2 userbtn"
                              onClick={() =>
                                fieldName === "excelSheetBase64"
                                  ? downloadFile(
                                      fieldValue,
                                      `${fieldName}.xlsx`,
                                      contentType
                                    )
                                  : downloadPdf(fieldValue, `${fieldName}.pdf`)
                              }
                            >
                              <MdFileDownload
                                style={{ color: "#FF8159" }}
                                size={20}
                              />
                            </button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </td>
                  <td>
                    <button
                      className="mt-1 userbtn"
                      onClick={() =>
                        handleDeleteDocument(document.ticketNumber)
                      }
                    >
                      <FaTrashAlt style={{ color: "#FF8159" }} size={16} />
                    </button>
                  </td>
                  <td>{document.totalReferralDiscount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Userdocuments;
